<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <WindowTitleBar title="Warping Receipt" @loadData="loadData" @close_window="closeWindow" >{{setTitle()}}</WindowTitleBar>


      <div class="card-body">

        <div class="row">

          <div class="col-md-1">
            <div class="form-group form-group-material ">
              <label class="control-label text-semibold">Shift</label>
              <select  id="cmbshift" class="form-control" autofocus v-model="voucher.shift.id">
                <option v-for="shift in shifts" v-bind:value="shift.id">
                  {{ shift.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material ">
              <label class="control-label text-semibold">Machine</label>
              <select class="form-control"  v-model="voucher.mac_id">
                <option v-for="machine in machines" v-bind:value="machine.id">
                  {{ machine.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material ">
              <label class="control-label text-semibold">Operator</label>
              <select class="form-control" v-model="voucher.emp_id">
                <option v-for="emp in emps" v-bind:value="emp.id">
                  {{ emp.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-5">

          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Date</label>
              <input type="date" class="form-control" v-model="voucher.doc_date">
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-md-12">

            <!-- Static mode -->
            <div class="card">


              <div class="card-body">

                <div class="form-group row">

                  <label class="col-form-label col-md-1">Quality</label>
                  <div class="col-md-2">
                    <select class="form-control" v-model="voucher.qua_id" >
                      <option v-for="quality in qualities" v-bind:value="quality.id">
                        {{ quality.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1 text-right">Color</label>
                  <div class="col-md-2">
                    <select class="form-control" v-if="voucher" v-model="voucher.col_id">
                      <option v-for="color in colorcharts" v-bind:value="color.id">
                        {{ color.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1 text-right">Qty</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Qty" min="0"  v-if="voucher" v-model="voucher.qty" @change="onContainerQtyChanged" >
                  </div>

                  <label class="col-form-label col-md-1 text-right">Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Total Weight" min="0"  v-if="voucher" v-model="voucher.weight"  >
                  </div>

                  <label class="col-form-label col-md-1 text-right">Yarn Waste Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Enter yarn Waste Weight" min="0" v-if="voucher" v-model="voucher.yarn_waste_weight" >
                  </div>

                </div>

              </div>
            </div>
            <!-- /static mode -->

          </div>
        </div>

        <div class="row">
          <div class="table-responsive" style="padding: 10px;" v-if="voucher.id > 1">
            <table  id="table" class="table table-bordered">
              <thead>
              <th>#</th>
              <th>Container No</th>
              <th></th>
              <th>Container Weight</th>
              <th>Gross Weight</th>
              <th>Yarn Weight</th>
              </thead>
              <tbody>
              <tr  v-for="(detail,idx) in containers" style="padding: 0px;">
                <td style="padding: 0px;">{{idx + 1}}</td>
                <td style="padding: 0px;">
                  <input type="text" class="form-control" placeholder="Enter Container No" maxlength="10" v-model="detail.name" @change="on_container_name_change(idx, detail.name)" >
                </td>
                <td style="padding: 0px;width: 70px;">
                  <button class="btn btn-outline-success" @change="on_container_name_change(idx, detail.name)" tabindex="-1">Load</button>
                </td>
                <td style="padding: 0px;text-align: right"> {{detail.base_weight | formatWeight}} </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" placeholder="Enter Gross Weight" min="0" v-if="voucher.id > 1"  v-model="detail.container.weight" @change="on_container_weight_changed(idx, detail)"  >
                </td>
                <td style="padding: 0px;text-align: right">
                  {{ detail.yarn_weight | formatWeight}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-6">

          </div>

          <div class="col-md-3 text-right">

            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
            <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>

import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'WarpingReceiptForm',
  store,
  components: {
    WindowTitleBar
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"col_id":1,"qua_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false},"list":[]}')
    }
  },
  data () {
    return {
      machines:[],
      shifts:[],
      emps:[],
      qualities:[],
      colorcharts:[],
      containers:[],
      items : new Map(),
      voucher: JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"col_id":1,"qua_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false},"list":[]}'),
    }
  },
  beforeMount () {
    const self = this;

    this.voucher = this.myvoucher;
    this.containers = [];
    this.voucher.list.forEach(function (d) {
      // d.container.weight = d.wgt;
      self.containers.push(d.container);
    });

  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;

    self.loadData();

    if(this.$data.voucher.doc_date == '0001-01-01' || this.$data.voucher.doc_date == '2000-01-01'){
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }
    self.$data.shifts.push({'id':2,'name':'Day'});
    self.$data.shifts.push({'id':3,'name':'Night'});

    $('.form-control-select2').select2();

    $('#cmbshift').focus();
  },
  filters:{
    formatWeight(val){
      try {
        return val.toFixed(3);
      }catch (e) {

      }

      return val;

    }
  },
  methods:{
    setTitle() {
      return this.voucher.id == 0 ? "Warping Receipt " : "Doc No: " + this.voucher.doc_no + "  Warping Receipt Updation";
    },
    indianFormat(val){
      return parseFloat(val).toFixed(2);
    },
    closeWindow(){
      const self = this;

      if(this.voucher.id > 1){
        self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"date":"0001-01-01","operator":{"id":0,"status":0,"emp_code":"","name":""},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"item":{"id":0,"nature":0,"name":"","p_name":"","process":{"id":0,"name":"","nature":0},"group":{"id":0,"type":0,"nature":0,"name":""},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"quality":{"id":0,"name":"","p_name":""},"category":{"id":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"style":{"id":0,"name":"","p_name":""},"unit":{"id":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"qty":0,"wgt":0,"yarn_waste_weight":0,"list":[]}');
      }else{
        this.$router.push("/home")
      }
    },
    clear(){
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"date":"0001-01-01","operator":{"id":0,"status":0,"emp_code":"","name":""},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"item":{"id":0,"nature":0,"name":"","p_name":"","process":{"id":0,"name":"","nature":0},"group":{"id":0,"type":0,"nature":0,"name":""},"design":{"id":0,"name":"","p_name":""},"color":{"id":0,"status":0,"name":"","p_name":""},"quality":{"id":0,"name":"","p_name":""},"category":{"id":0,"name":"","p_name":""},"brand":{"id":0,"name":"","print_name":""},"style":{"id":0,"name":"","p_name":""},"unit":{"id":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"qty":0,"wgt":0,"yarn_waste_weight":0,"list":[]}');
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.containers = [];
    },
    onContainerQtyChanged(){
      const self = this;

      self.$data.containers = [];
      for (let i=0; i < self.$data.voucher.qty; i++) {
        self.$data.containers.push(JSON.parse('{"cont_id":0,"item_id":1,"yarn_weight":0,"container":{"id":0,"name":"","type":"","status":"","base_weight":0,"weight":0,"yarn_weight":0,"item_id":1,"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}},"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":2,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":2,"type":0,"name":"Kgs","status":"Active","digits":3},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"Green","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}}'));
      }
    },
    on_container_name_change(idx, name){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $("#table").block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.containers[idx].id = "";
      self.$data.containers[idx].id = "";
      self.$data.containers[idx].name = "";
      self.$data.containers[idx].base_weight = 0;
      self.$data.containers[idx].weight = 0;
      self.$data.containers[idx].yarn_weight = 0;

      fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${name}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {


          self.$data.containers[idx].id = resp.data.id;
          self.$data.containers[idx].name = resp.data.name;
          self.$data.containers[idx].base_weight = resp.data.base_weight;
          self.$data.containers[idx].weight = 0;

          self.$data.containers[idx].container = resp.data;
          self.$data.containers[idx].yarn_weight = resp.data.yarn_weight;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $("#table").unblock();
      });


    },
    on_container_weight_changed(idx, detail){
      const self = this;
      self.$data.containers[idx].yarn_weight = parseFloat(detail.container.weight) - parseFloat(detail.container.base_weight);
      // detail.yarn_weight = parseFloat(detail.container.weight) - parseFloat(detail.container.base_weight);
    },
    loadData() {
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      self.$data.machines = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/2`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          self.$data.machines = resp.data;
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });


      self.$data.emps = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if( _.isArray(resp.data)){
            self.$data.emps = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });

      self.$data.colorcharts = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if( _.isArray(resp.data)){
            self.$data.colorcharts = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });

      //Qualities
      self.$data.qualities = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(  _.isArray(resp.data)){
            self.$data.qualities = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });


    },
    removeRow(index){
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
        this.sumRowAmountTotal();
      }
    },
    saveVoucher(){
      const self = this;

      self.$data.voucher.finyear = self.$store.state.user.finyear;
      self.$data.voucher.status = "Active";
      self.$data.voucher.cmp_id = self.$store.state.user.company.id;

      self.$data.voucher.emp_id = parseInt(self.$data.voucher.emp_id);
      self.$data.voucher.shift_id = parseInt(self.$data.voucher.shift_id);
      self.$data.voucher.mac_id = parseInt(self.$data.voucher.mac_id);


      self.$data.voucher.yarn_container = 3;
      self.$data.voucher.qty = parseFloat(self.$data.voucher.qty);
      self.$data.voucher.weight = parseFloat(self.$data.voucher.weight);
      self.$data.voucher.yarn_weight = 0;
      self.$data.voucher.yarn_waste_weight = parseFloat(self.$data.voucher.yarn_waste_weight);
      self.$data.voucher.list = [];

      // let containers = [];
      // self.$data.voucher.yarn_weight = 0;
      self.$data.containers.forEach(function (det) {
        self.$data.voucher.yarn_weight +=  parseFloat(det.container.weight) - parseFloat(det.container.base_weight);
        let detail = {"cont_id":0,"item_id":1,"yarn_weight":0,"container":{"id":0,"name":"","type":"","status":"","base_weight":0,"weight":0,"yarn_weight":0,"item_id":1,"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}},"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":2,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":2,"type":0,"name":"Kgs","status":"Active","digits":3},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"Green","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}};

        detail = det;
        detail.cont_id = det.container.id;
        detail.container.weight = parseFloat(det.container.weight);
        detail.yarn_weight = parseFloat(det.container.weight) - parseFloat(det.container.base_weight);
        self.$data.voucher.list.push(detail);

        self.$data.voucher.yarn_weight += detail.yarn_weight;
      });


      if(self.$data.voucher.mac_id < 1){
        alert("Invalid Machine");
        return
      }else if(self.$data.voucher.col_id < 1){
        alert("Invalid Color");
        return
      }else if(self.$data.voucher.qua_id < 1){
        alert("Invalid Quality");
        return
      }else if(self.$data.voucher.qty < 1){
        alert("Invalid Quantity");
        return
      }else if(self.$data.voucher.yarn_waste_weight < 0){
        alert("Invalid Yarn Wastage Weight");
        return
      }else if(self.$data.voucher.list.length < 0){
        alert("Invalid Containers Data");
        return
      }

      let myvoucher = JSON.parse( JSON.stringify(self.$data.voucher));
      myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

      const requestOptions = {
        method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(myvoucher)
      };

      $("#mycard").block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      console.log(JSON.stringify(myvoucher));

      fetch(`${process.env.VUE_APP_ROOT_API}api/warping/receipt`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", onClose: () => {
              $('#cmbshift').focus();
            }} );

          self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"emp_code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_weight":0,"raw_mat_excess":0,"remarks":"","list":[]}');
          self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

          self.$data.containers = [];

          self.$emit('warping_receipt_saved');

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $("#mycard").unblock();
      });
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
